/*
 * 업무구분: 고객
 * 화 면 명: MSPCM116P
 * 화면설명: 고객명 검색
 * 작 성 일: 2022.05.26
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <!-- 검색조건 영역 : 접근한 화면에 따라 show를 변경해줌 -->

      <!-- 전체고객 -->
      <ur-box-container v-if="pPage === 'MSPCM115M'" alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC"/>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap">
            <span class="info-title fs14rem fwn crTy-bk7">고객구분</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="row">
            <msp-bottom-select ref="bottomSelect" :items="customerTypes" v-model="customerType" underline class="ns-dropdown-sheet w110px mt0 mr10" bottom-title="고객구분"/>
            <mo-text-field ref="error" type="none" class="mb0 flex-1" clearable underline v-model="searchKeyword" placeholder="고객명을 입력해주세요." @keyup.enter="fn_Search"/>  
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">성별</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="sxdsCd" solid primary class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in sxdsCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">관계</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="mnCustRltnCds" v-model="mnCustRltnCd" underline class="ns-dropdown-sheet mb0" placeholder="전체" bottom-title="관계" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb30 mb50">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">연령(실제생년월일 기준)</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="iDNumbers" v-model="iDNumber" underline class="ns-dropdown-sheet mb0" placeholder="전체" bottom-title="연령" closeBtn/>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- 접촉정보고객 -->
      <ur-box-container v-if="pPage === 'MSPCM121M' || pPage === 'MSPCM303M' || pPage === 'MSPCM240M' || pPage === 'MSPCM220M' || pPage === 'MSPCM162P' || pPage === 'MSPCM201M' || pPage === 'MSPCM250M' || pPage === 'MSPCM210M'" alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC"/>
        <ur-box-container v-if="pPage !== 'MSPCM210M'" alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="row">
            <mo-text-field ref="error" type="none" class="mb0 flex-1" clearable underline v-model="searchKeyword" placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- 필수컨설팅 만료예정고객 -->
      <ur-box-container v-if="pPage === 'MSPCM217M'" alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC"/>
      </ur-box-container>

      <!-- 이벤트 고객 -->
      <ur-box-container v-if="pPage === 'MSPCM124M'" alignV="start" componentid="" direction="column" class="ns-info-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC"/>
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field v-model="searchKeyword" ref="error" type="none" class="mb0" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객구분</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="stEvtTypCds" v-model="stEvtTypCd" @input="fn_BindSelect2(stEvtTypCd)" underline class="ns-dropdown-sheet mb0" placeholder="전체" bottom-title="고객구분" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">중분류</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="stEvtCdKeys" v-model="stEvtCdKey" underline class="ns-dropdown-sheet mb0" placeholder="전체" bottom-title="중분류" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt0 mb80">
          <span class="ns-title mb0 fs14rem fwn crTy-bk7">발생일자</span>
          <div class="add-date">
            <mo-date-picker bottom v-model="startDate" placeholder="선택일" class="ns-date-picker" />
            <span class="tilde"></span>
            <mo-date-picker bottom v-model="endDate" placeholder="선택일" class="ns-date-picker" />
          </div>
        </ur-box-container>
      </ur-box-container>

      <!-- 관심 고객 -->
      <ur-box-container v-if="pPage === 'MSPCM317M' || pPage === 'MSPCM320D'" alignV="start" componentid="ur_box_container_004" direction="column" class="ns-sch-area pt30 pb0">
        <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC"/>
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="row">
            <mo-text-field ref="error" type="none" class="mb0 flex-1" clearable underline v-model="searchKeyword" placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <!-- 인수 -->
        <ur-box-container v-if="pPage === 'MSPCM317M'" alignV="start" componentid="ur_box_container_005" direction="column" class="input-box mb45">
          <mo-segment-wrapper solid primary v-model="cccDtScCd" class="ns-btn-s2-4 ns-style2">
            <mo-segment-button v-for="(item, idx) in cccDtScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
        <!-- 최근터치일 -->
        <ur-box-container v-if="pPage === 'MSPCM317M'" alignV="start" componentid="ur_box_container_006" direction="column" class="input-box mb45">
          <mo-segment-wrapper solid primary v-model="lstUnTuchPrdCd" class="ns-btn-s2-4 ns-style2">
            <mo-segment-button value=" ">최근 터치일<br/>전체</mo-segment-button>
            <mo-segment-button value="2">1개월이내</mo-segment-button>
            <mo-segment-button value="3">6개월이내</mo-segment-button>
            <mo-segment-button value="4">1년이상<br/>(회수대상)</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container>

      <!-- 수금인수 고객 -->
      <ur-box-container v-if="pPage === 'MSPCM322M'" alignV="start" componentid="ur_box_container_007" direction="column" class="ns-sch-area pt30 pb0">
        <!-- 인수구분 : 사용하지않음 -->
        <msp-bottom-select v-show="false" class="ns-dropdown-sheet scrolling-200"
            :items="inputUndtkRsnScCds" v-model="inputUndtkRsnScCd" closeBtn underline scrolling/>
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC"/>
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field v-model="searchKeyword" ref="error" type="none" class="mb0" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">인수월</span>
          </div>
          <!-- 날짜 -->
          <!-- 디자인 보완 요청 - 김정현 프로 (2022-08-02) -->
          <!-- <div class="ns-date-picker mb10">
            <mo-month-picker v-model="startMonth" :min-date="minMonth" :max-date="maxMonth" placeholder="인수월 시작" />
          </div>
          <br>
          <div class="ns-date-picker">
            <mo-month-picker v-model="endMonth" :min-date="minMonth" :max-date="maxMonth" placeholder="인수월 종료" />
          </div> -->
          <div class="ns-date-picker multi">
            <mo-month-picker v-model="startMonth" :max-date="searchStartMaxDate" placeholder="인수월 시작" @input="fn_ChangeStartDate" /><span>~</span>
            <mo-month-picker v-model="endMonth" :min-date="searchEndMinDate" :max-date="searchEndMaxDate"  placeholder="인수월 종료" />
          </div>
        </ur-box-container>
      </ur-box-container>

      <!-- 추가계약 추천고객 -->
      <ur-box-container v-if="pPage === 'MSPCM330M'" alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field v-model="searchKeyword" ref="error" type="none" class="mb0" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">성별</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="upselSxdsCd" solid primary class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in upselSxdsCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">연령대(주민등록번호 기준)</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="upselAgeItvCds" v-model="upselAgeItvCd" underline class="ns-dropdown-sheet mb0" placeholder="전체" bottom-title="연령" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">추가계약 가능성</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="upselGrdNm" solid primary class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in upselGrdNms" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">추정소득(마케팅동의 Y 고객만 제공)</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <msp-bottom-select ref="bottomSelect" :items="upselAsptnIncmItvCds" v-model="upselAsptnIncmItvCd" underline class="ns-dropdown-sheet mb0" placeholder="전체" bottom-title="추정소득" closeBtn/>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- 비대면동의 현황조회 -->
      <ur-box-container v-if="pPage === 'MSPCM135M'" alignV="start" componentid="" direction="column" class="ns-sch-area pt30 pb0">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0 pb20">
          <span class="ns-title mb0 fs14rem fwn crTy-bk7">발송일자</span>
          <div class="add-date">
            <mo-date-picker bottom v-model="startDate" placeholder="선택일" class="ns-date-picker" />
            <span class="tilde"></span>
            <mo-date-picker bottom v-model="endDate" placeholder="선택일" class="ns-date-picker" />
          </div>
        </ur-box-container>
        <SearchCondOrgCM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData116P" @selectResult="fn_SlectCnsltResultRC" :disabled="!!telNo"/>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field v-model="searchKeyword" ref="error" type="none" class="mb0" clearable underline placeholder="고객명을 입력해주세요."/>  
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">휴대폰번호</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field v-model="telNo" ref="error" type="number" class="mb0" clearable underline placeholder="휴대폰번호를 입력해주세요." maxlength="11" />  
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- /검색조건 영역 -->

      <!-- 초기화/조회 버튼 -->
      <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
        </div>
      </ur-box-container>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height150"></div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import moment from 'moment'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM116P',
  // 현재 화면 ID
  screenId: 'MSPCM116P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    SearchCondOrgCM
  },
  // 화면명
  props: {
    pPage: String, // 부모 페이지명
    pSearchKeyword: String, // 고객명
    pIsMngr: Boolean, // 지점장여부
    pOrgData: { // 조직정보
      type: Object,
      default: {}
    },
    pCustomerType: String, // 고객구분
    pStEvtTypCd: String, // 고객구분
    pStEvtCdKey: String,
    pStartDate: String, // 조회시작일자
    pEndDate: String, // 조회종료일자
    pCccDtScCd: String, // 관심고객 인수구분
    pLstUnTuchPrdCd: String, // 관심고객 최근터치일
    pUndtkRsnScCdKey: String, // 수금인수고객 인수구분
    // 전체고객
    pSxdsCd: String,  // 성별
    pMnCustRltnCd: String,  // 관계
    pIDNumber: String,  // 연령
    // 정보활용동의 현황
    pInqrScCd: String,
    pInfoPrcusCnsntTypCd: String,
    pInfoPrcusCnsntStatCd: String,
    pInfoPrcusCnsntPathCd: String,
    // 추가계약 추천고객
    pAsptnIncmItvCd: String,
    pUpselGrdNm: String,
    pAgeItvCd: String,
    // 비대면동의 현황조회
    pTelNo: String
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      isInit: false, // 초기화 여부
      userInfo: {},
      toastMsg: '', // 문자발송 toast 문구
      searchKeyword: '', // 고객명
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      isSucs: false, // 리턴값 세팅여부

      // 조직
      lv_SelectOrg: {}, // 조직 컴포넌트 데이터
      pOrgData116P: {}, // 조직 정보
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부

      // 전체고객
      customerType: '', // 고객구분 선택값
      customerTypes: [ // 고객구분 목록
        { value: '', text: '전체고객' },
        { value: '99', text: '계약고객' },
        { value: '03', text: '본인모집고객' },
        { value: '04', text: '타인모집고객' },
        { value: '05', text: '관심고객' },
        { value: '02', text: '임시고객' },
        { value: '01', text: '가망고객' },
      ],
      sxdsCd: '99',  // 성별
      sxdsCds: [
        { value: '99', text: '전체' },
        { value: '2', text: '남성' },
        { value: '1', text: '여성' },
      ],
      mnCustRltnCd: '', // 관계
      mnCustRltnCds: [
        { value: '', text: '전체' },
        { value: '01', text: '본인' },
        { value: '02', text: '배우자' },
        { value: '03', text: '자녀' },
        { value: '04', text: '손자' },
        { value: '05', text: '부모' },
        { value: '06', text: '조부모' },
        { value: '07', text: '친척' },
        { value: '08', text: '기타가족' },
      ],
      iDNumber: '', // 연령
      iDNumbers: [
        { value: '', text: '전체' },
        { value: '10', text: '20대미만' },
        { value: '20', text: '20~29세' },
        { value: '30', text: '30~39세' },
        { value: '40', text: '40~49세' },
        { value: '50', text: '50~59세' },
        { value: '60', text: '60~69세' },
        { value: '70', text: '70세이상' },
      ],

      // 이벤트고객
      stEvtTypCd: '', // 고객구분 선택값
      stEvtTypCds: [ // 고객구분 목록
        { value: '', text: '전체' },
        { value: '00', text: '계약월도래' },
        { value: '10', text: '생일' },
        { value: '20', text: '상령일' },
        { value: '30', text: '계약기념일' },
        { value: '40', text: '납입완료' },
        { value: '50', text: '만기도래' },
        { value: '60', text: '장기미터치' },
        { value: '80', text: '기타' }
      ],
      // 추가계약 추천고객 성별
      upselSxdsCd: '0',
      upselSxdsCds: [
        { value: '0', text: '전체' },
        { value: '1', text: '남성' },
        { value: '2', text: '여성' }
      ],
      // 추가계약 추천고객 가능성
      upselGrdNm: '전체',
      upselGrdNms: [
        { value: '전체', text: '전체' },
        { value: '상', text: '상' },
        { value: '중', text: '중' },
      ],
      // 추가계약 추천고객 연령대
      upselAgeItvCd: '0',
      upselAgeItvCds: [
        { value: '0', text: '전체' },
        { value: '1', text: '20대미만' },
        { value: '2', text: '20대' },
        { value: '3', text: '30대' },
        { value: '4', text: '40대' },
        { value: '5', text: '50대' },
        { value: '6', text: '60대' },
        { value: '7', text: '70대 이상' }
      ],
      // 추가계약 추천고객 추정소득
      upselAsptnIncmItvCd: '00',
      upselAsptnIncmItvCds: [
        { value: '00', text: '전체' },
        { value: '01', text: '2천만원미만' },
        { value: '02', text: '2천만원이상' },
        { value: '03', text: '4천만원이상' },
        { value: '04', text: '6천만원이상' },
        { value: '05', text: '8천만원이상' },
        { value: '06', text: '1억이상' }
      ],
      stEvtCdKey: '', // 고객구분 중분류 선택값
      stEvtCdKeys: [  // 고객구분 중분류 목록
        { value: '', text: '전체' },
      ], 

      startDate: '', // 조회시작일자
      endDate: '', // 조회종료일자

      // 관심고객
      cccDtScCd: '00', // 인수 (default 전체)
      cccDtScCds: [], // 인수 OBJ
      lstUnTuchPrdCd: ' ', // 최근터치일 (default 전체)
      lstUnTuchPrdCdTxt: '', // 최근터치일 텍스트

      // 수금인수고객
      inputUndtkRsnScCd: '', // 구분
      inputUndtkRsnScCds: [], // 구분 OBJ
      startMonth: [], // 조회시작일자
      endMonth: [], // 조회종료일자
      searchEndMinDate: '1970-01-01', // 조회종료최소일자
      searchEndMaxDate: '1970-01-01', // 조회종료최대일자
      searchStartMaxDate: moment(new Date()).format('YYYY-MM-DD'), // 조회시작최대일자
      curDate: moment(new Date()).format('YYYY-MM-DD'), // 현재일자 
      telNo: '' // 휴대폰번호
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},
  
  /** watch 정의 영역 */
  watch: {
    searchKeyword () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.searchKeyword)) {
        if (englishOrKorean.test(this.searchKeyword)) {
          this.searchKeyword = this.searchKeyword.toUpperCase()
        }
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 공통코드 조회
    const lv_Vm = this
    let codeList = []

    switch (lv_Vm.pPage) {
      // 관심고객
      case 'MSPCM317M' :
      case 'MSPCM320D' :
        codeList = lv_Vm.getStore('code').getters.getState.get('CCC_DT_SC_CD')

        if (codeList !== undefined && codeList !== null && codeList.length !== 0) {
          lv_Vm.fn_RetrieveUserData_CCC(codeList)
        }
        break
      // 수금인수고객
      case 'MSPCM322M':
        codeList = lv_Vm.getStore('code').getters.getState.get('UNDTK_RSN_SC_CD')

        if (codeList !== undefined && codeList !== null && codeList.length !== 0) {
          lv_Vm.fn_RetrieveUserData_UNDTK(codeList)
        }
        break
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM116P')

    // 조직정보 세팅
    this.pOrgData116P = this.pOrgData

    switch (this.pPage) {
      // 전체고객
      case 'MSPCM115M':
        this.searchKeyword = this.pSearchKeyword
        this.customerType = this.pCustomerType
        this.sxdsCd = this.pSxdsCd
        this.mnCustRltnCd = this.pMnCustRltnCd
        this.iDNumber = this.pIDNumber

        if (this.sxdsCd === '') this.sxdsCd = '99'
        break
      // 접촉정보고객 / 프리미엄사랑서비스 / 수익증권추천고객 / 미승인고객 / 캠페인고객 직접선정
      case 'MSPCM121M':
      case 'MSPCM303M':
      case 'MSPCM240M': 
      case 'MSPCM220M':
      case 'MSPCM162P':
      case 'MSPCM201M':
      case 'MSPCM250M':
        this.searchKeyword = this.pSearchKeyword
        break
      // 이벤트고객
      case 'MSPCM124M':
        this.searchKeyword = this.pSearchKeyword
        this.stEvtTypCd = this.pStEvtTypCd
        this.fn_BindSelect2(this.stEvtTypCd)
        this.$nextTick( () => { 
          this.stEvtCdKey = this.pStEvtCdKey
        })
        this.startDate = this.pStartDate
        this.endDate = this.pEndDate
        break
      // 삭제예정고객
      case 'MSPCM217M':
        break
      // 관심고객
      case 'MSPCM317M':
      case 'MSPCM320D':
        this.searchKeyword = this.pSearchKeyword
        this.cccDtScCd = this.pCccDtScCd
        this.lstUnTuchPrdCd = this.pLstUnTuchPrdCd
        break
      // 수금인수고객
      case 'MSPCM322M':
        this.searchKeyword = this.pSearchKeyword
        this.startMonth = [String(moment(this.pStartDate).format('YYYY')), String(moment(this.pStartDate).format('MM'))]
        this.endMonth = [String(moment(this.pEndDate).format('YYYY')), String(moment(this.pEndDate).format('MM'))]
        this.inputUndtkRsnScCd = this.pUndtkRsnScCdKey
        this.fn_ResetSearchRangeDate();
        break
      // 추가계약 추천고객
      case 'MSPCM330M':
        this.searchKeyword = this.pSearchKeyword
        this.upselSxdsCd = this.pSxdsCd
        this.upselAsptnIncmItvCd = this.pAsptnIncmItvCd
        this.upselGrdNm = this.pUpselGrdNm
        this.upselAgeItvCd = this.pAgeItvCd

        if (this.upselGrdNm === '') this.upselGrdNm = '전체'
        break
      // 비대면동의 현황조회
      case 'MSPCM135M':
        this.searchKeyword = this.pSearchKeyword
        this.telNo = this.pTelNo
        this.startDate = this.pStartDate
        this.endDate = this.pEndDate
        break
    }
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultRC()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultRC (pData) {
      if ( this.isShowCnslt ) {
        this.lv_SelectOrg = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_RetrieveUserData_CCC
     * 설명       : 관심고객 인수구분 세팅
     ******************************************************************************/
    fn_RetrieveUserData_CCC (codeList) {
      // key,label 값 UI-DEV 기준에 맞춰 value,text로 수정
      for (let i = 0; i < codeList.length; i++) {
        let param = {}

        param.value = codeList[i].key

        // 전체의 경우 텍스트 '인수 전체'로 변경
        if (i === 0) {
          param.text = '인수 ' + codeList[i].label
        } else {
          param.text = codeList[i].label
        }
        
        this.cccDtScCds.push(param)
      }
    },

    /******************************************************************************
     * Function명 : fn_RetrieveUserData_UNDTK
     * 설명       : 수금인수고객 인수구분 세팅
     *             전체로 기본 셋팅, 필요시 사용
     ******************************************************************************/
    fn_RetrieveUserData_UNDTK (codeList) {
      let undtkRsnScCdsList = this.$bizUtil.parseSegmentHeadCode(codeList, '0', '전체')

      // key,label 값 UI-DEV 기준에 맞춰 value,text로 수정
      for (let i = 0; i < undtkRsnScCdsList.length; i++) {
        let param = {
          value: undtkRsnScCdsList[i].key,
          text: undtkRsnScCdsList[i].label
        }
        this.inputUndtkRsnScCds.push(param)
      }
    },

    /******************************************************************************
     * Function명 : fn_BindSelect2
     * 설명       : 이벤트고객 고객구분 셀렉트 구분 변경시 조회
     ******************************************************************************/
    fn_BindSelect2 (value) {
      switch (value) {
        case '':
          this.stEvtCdKeys = [{value: '', text: '전체'}]
          this.stEvtCdKey = ''
          break
        case '00':
          this.stEvtCdKeys = [{value: '00', text: '전체'}, {value: '09', text: '종합안내장'}, {value: '08', text: '배당안내'}]
          this.stEvtCdKey = '00'
          break
        case '10':
          this.stEvtCdKeys = [{value: '10', text: '전체'}, {value: '11', text: '본인생일'}, {value: '12', text: '배우자생일'}, {value: '13', text: '자녀생일'}]
          this.stEvtCdKey = '10'
          break
        case '20':
          this.stEvtCdKeys = [{value: '20', text: '전체'}, {value: '21', text: '본인상령일'}, {value: '22', text: '배우자상령일'}]
          this.stEvtCdKey = '20'
          break
        case '30':
          this.stEvtCdKeys = [{value: '30', text: '전체'}, {value: '31', text: '100일기념일'}, {value: '32', text: '6개월기념월'}, {value: '33', text: '1년기념일'}, {value: '34', text: '2년기념일'}]
          this.stEvtCdKey = '30'
          break
        case '40':
          this.stEvtCdKeys = [{value: '40', text: '전체'}, {value: '41', text: '납입완료(당월)'}, {value: '42', text: '납입완료6개월前'}]
          this.stEvtCdKey = '40'
          break
        case '50':
          this.stEvtCdKeys = [{value: '50', text: '전체'}, {value: '51', text: '만기도래(당월)'}, {value: '50', text: '만기도래(6개월前)'}]
          this.stEvtCdKey = '50'
          break
        case '60':
          this.stEvtCdKeys = [{value: '61', text: '전체'}]
          this.stEvtCdKey = '61'
          break
        case '80':
          this.stEvtCdKeys = [{value: '80', text: '전체'}, {value: '81', text: '자녀입학도래'}, {value: '82', text: '분할보험금지급'}, {value: '83', text: '자녀만15세도래(D-3개월)'}, {value: '84', text: '본인결혼기념일'}]
          this.stEvtCdKey = '80'
          break

        default:
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckDateValue
     * 설명       : 달력 유효성 검사 - 일달력
     ******************************************************************************/
    fn_CheckDateValue () {
      let returnValue = true

      // 컨설턴트를 선택해주십시오 추가해야함 ECMC049
      if (new Date(this.startDate) > new Date(this.endDate)) {
        returnValue = false
        this.toastMsg = '조회 시작일은 종료일 보다 클 수 없습니다.'
        this.$refs.snackbar.show({ text: this.toastMsg })
      }

      if (new Date(this.startDate) < new Date(moment(this.nowDate).add(-3, 'months'))) {
        returnValue = false
        this.toastMsg = '검색일자의 범위는 3개월(93일) 이내만 가능합니다.'
        this.$refs.snackbar.show({ text: this.toastMsg })
      }

      return returnValue
    },

    /******************************************************************************
     * Function명 : fn_CheckMonthValue
     * 설명       : 달력 유효성 검사 - 월달력
     ******************************************************************************/
    fn_CheckMonthValue () {
      let returnValue = true

      // 컨설턴트를 선택해주십시오 추가해야함 ECMC049
      if (new Date(this.fn_SetDate(this.startMonth)) > new Date(this.fn_SetDate(this.endMonth))) {
        returnValue = false
        this.toastMsg = '조회 시작일은 종료일 보다 클 수 없습니다.'
        this.$refs.snackbar.show({ text: this.toastMsg })
      }

      return returnValue
    },

    /******************************************************************************
     * Function명 : fn_SetDate
     * 설명       : 해당월 첫째날 또는 마지막날 셋팅
     * Params     : 달력 선택값 (dateArray: ['YYYY', 'MM'])
     *              시작종료일 구분값 (type: 's' 또는 'e')
     * Return     : YYYY-MM-DD
     ******************************************************************************/
    fn_SetDate (dateArray, type) {
      let year = ''
      let month = ''
      let date = ''

      year = dateArray[0]
      if (Number(dateArray[1]) < 10) {
        if (dateArray[1].length < 2) {
          dateArray[1] = '0' + dateArray[1]
        }
      }
      month = dateArray[1]

      if (type === 's') {
        date = this.$bizUtil.getFirstDayOfMonth(new Date(year + '-' + month), 'String') // 해당월 1일
      } else {
        date = this.$bizUtil.getLastDayOfMonth(new Date(year + '-' + month), 'String') // 해당월 말일
      }

      return date
    },

    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      this.pOrgData116P = {}

      // 관리자 접근 시 조직정보 초기화 후, 재조회
      if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

      switch (this.pPage) {
        // 전체고객
        case 'MSPCM115M':
          this.customerType = '' // 고객구분
          this.searchKeyword = '' // 고객명
          this.sxdsCd = '99'  // 성별
          this.mnCustRltnCd = ''  // 관계
          this.iDNumber = ''  // 연령
          break
        // 접촉정보고객 / 프리미엄사랑서비스 / 수익증권추천고객 / 미승인고객 / 캠페인고객 직접선정
        case 'MSPCM121M':
        case 'MSPCM303M':
        case 'MSPCM240M':  
        case 'MSPCM220M':
        case 'MSPCM162P':
        case 'MSPCM201M':
        case 'MSPCM250M':  
          this.searchKeyword = '' // 고객명
          break
        // 이벤트고객
        case 'MSPCM124M':
          this.stEvtTypCd = '' // 고객구분
          // 중분류 설정
          this.fn_BindSelect2(this.stEvtTypCd)
          this.startDate = this.$bizUtil.getDateToString(new Date(), '-') // 시작일 : 오늘
          this.endDate = this.$bizUtil.getLastDayOfNowMonth('String'), // 종료일 : 당월 말일
          this.searchKeyword = '' // 고객명
          break
        // 삭제예정고객
        case 'MSPCM217M':
          break
        // 관심고객
        case 'MSPCM317M':
          this.cccDtScCd = '00' // 인수구분('전체')
          this.lstUnTuchPrdCd = ' ' // 최근터치일('전체')
          this.searchKeyword = '' // 고객명
          break
        case 'MSPCM320D':
          this.searchKeyword = '' // 고객명
          break
        // 수금인수고객
        case 'MSPCM322M':
          this.inputUndtkRsnScCd = '0' // 인수구분('전체')
          let setSdate = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.nowDate).add(-6, 'months')), 'String') // 해당월 1일
          this.startMonth = [String(moment(setSdate).format('YYYY')), String(moment(setSdate).format('MM'))]
          let setEdate = this.$bizUtil.getLastDayOfMonth(new Date(moment(new Date()).format('YYYY-MM-DD')), 'String') // 해당월 말일
          this.endMonth = [String(moment(setEdate).format('YYYY')), String(moment(setEdate).format('MM'))]
          this.searchKeyword = '' // 고객명
          this.fn_ResetSearchRangeDate();
          break
        // 추가계약 추천고객
        case 'MSPCM330M':
          this.searchKeyword = '' // 고객명
          this.upselSxdsCd = '0'  // 성별
          this.upselAgeItvCd = '0'  // 연령대
          this.upselGrdNm = '전체'  // 추가계약가능성
          this.upselAsptnIncmItvCd = '00'  // 추정소득
          break
        // 비대면동의 현황조회
        case 'MSPCM135M':
          this.searchKeyword = '' // 고객명
          this.telNo = ''  // 휴대폰번호
          this.startDate = ''  // 조회시작일
          this.endDate = ''  // 조회종료일
          break
        // 접촉정보고객 / 프리미엄사랑서비스 / 수익증권추천고객 / 미승인고객 / 캠페인고객 직접선정
      }
    },

    /******************************************************************************
     * Function명 : fn_SetReturnVal
     * 설명       : 팝업 닫힐때, 최종 return 입력값 셋팅
     ******************************************************************************/
    fn_SetReturnVal () {
      this.lv_ReturnVal = {} // 초기화
      this.isSucs = false // 리턴값 세팅여부

      // 초기화 여부
      this.isInit = false

      // 조회조건 텍스트 배열
      this.lv_ReturnStr = [] // 초기화

      // 조직정보 세팅
      this.lv_ReturnVal.cnsltEno = !this.$bizUtil.isEmpty(this.lv_SelectOrg.cnslt) ? this.lv_SelectOrg.cnslt.key : this.userInfo.userId // 컨설턴트 사번
      this.lv_ReturnVal.hofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : this.userInfo.onpstHofOrgNo // 선택사업부
      this.lv_ReturnVal.dofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : this.userInfo.onpstDofNo    // 선택지역단
      this.lv_ReturnVal.fofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : this.userInfo.onpstFofOrgNo // 선택지점
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg // 조회 조직 데이터

      switch (this.pPage) {
        // 전체고객
        case 'MSPCM115M':
          // 조회조건: 고객명, 고객구분 텍스트
          this.lv_ReturnStr = [this.searchKeyword, this.customerTypes.find(e => e.value === this.customerType).text,
                              this.sxdsCds.find(e => e.value === this.sxdsCd).text, this.mnCustRltnCds.find(e => e.value === this.mnCustRltnCd).text,
                              this.iDNumbers.find(e => e.value === this.iDNumber).text]

          if (this.sxdsCd === '99') this.sxdsCd = ''

          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명
          this.lv_ReturnVal.customerType = this.customerType // 고객구분
          this.lv_ReturnVal.sxdsCd = this.sxdsCd  // 성별
          this.lv_ReturnVal.mnCustRltnCd = this.mnCustRltnCd  // 관계
          this.lv_ReturnVal.iDNumber = this.iDNumber  // 연령

          // 초기화 여부 체크
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword) && this.lv_ReturnVal.customerType === '' && this.lv_ReturnVal.sxdsCd === ''
               && this.lv_ReturnVal.mnCustRltnCd === '' && this.lv_ReturnVal.iDNumber === '') {
            if (this.pIsMngr) {
              if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
              else this.isInit = false
            } else this.isInit = true
          }

          this.isSucs = true // 리턴값 세팅여부
          break
        // 접촉정보고객 / 프리미엄사랑서비스 / 관심고객 회수대상 / 수익증권추천고객 / 미승인고객 / 캠페인고객 직접선정 / 세미나활동(FC) / 대출가망고객
        case 'MSPCM121M':
        case 'MSPCM303M':
        case 'MSPCM320D':
        case 'MSPCM240M':  
        case 'MSPCM220M':
        case 'MSPCM162P':
        case 'MSPCM201M':
        case 'MSPCM250M':
          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명

          // 초기화 여부 체크
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword)) {
            if (this.pIsMngr) {
              if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
              else this.isInit = false
            } else this.isInit = true
          }

          // 조회조건: 고객명
          this.lv_ReturnStr = [this.lv_ReturnVal.searchKeyword]

          this.isSucs = true // 리턴값 세팅여부
          break
        // 이벤트고객
        case 'MSPCM124M':
          if (!this.fn_CheckDateValue()) return // 유효성 검사

          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명
          this.lv_ReturnVal.stEvtTypCd = this.stEvtTypCd // 고객구분
          this.lv_ReturnVal.stEvtCdKey = this.stEvtCdKey // 고객구분 중분류
          this.lv_ReturnVal.startDate = this.startDate // 조회시작일자
          this.lv_ReturnVal.endDate = this.endDate // 조회종료일자

          // 초기화 여부 체크
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword) && this.lv_ReturnVal.stEvtTypCd === '' && this.lv_ReturnVal.stEvtCdKey === ''
              && this.lv_ReturnVal.startDate === this.$bizUtil.getDateToString(new Date(), '-') && this.lv_ReturnVal.endDate === this.$bizUtil.getLastDayOfNowMonth('String')) {
            if (this.pIsMngr) {
              if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
              else this.isInit = false
            } else this.isInit = true
          }

          // 조회조건: 고객명, 고객구분, 중분류, 조회시작일자, 조회종료일자
          this.lv_ReturnStr = [this.lv_ReturnVal.searchKeyword, this.stEvtTypCds.find(e => e.value === this.stEvtTypCd).text, this.stEvtCdKeys.find(e => e.value === this.stEvtCdKey).text, this.lv_ReturnVal.startDate, this.lv_ReturnVal.endDate]

          this.isSucs = true // 리턴값 세팅여부
          break
        // 삭제예정고객, 내그룹
        case 'MSPCM217M':
        case 'MSPCM210M':
          if (this.pIsMngr) {
            if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
            else this.isInit = false
          } else this.isInit = true
          this.isSucs = true // 리턴값 세팅여부
          break
        // 관심고객
        case 'MSPCM317M':
          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명
          this.lv_ReturnVal.cccDtScCd = this.cccDtScCd // 인수구분
          this.lv_ReturnVal.lstUnTuchPrdCd = this.lstUnTuchPrdCd // 최근터치일

          // 초기화 여부 체크
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword) && this.lv_ReturnVal.cccDtScCd === '00' && this.lv_ReturnVal.lstUnTuchPrdCd === ' ') {
            if (this.pIsMngr) {
              if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
              else this.isInit = false
            } else this.isInit = true
          }

          // 조회조건: 고객명, 인수구분, 최근터치일
          this.lstUnTuchPrdCdTxt = (this.lstUnTuchPrdCd === '2')? '1개월이내': (this.lstUnTuchPrdCd === '3')? '6개월이내': (this.lstUnTuchPrdCd === '4')? '1년이상(회수대상)': '최근 터치일 전체'
          this.lv_ReturnStr = [this.lv_ReturnVal.searchKeyword, this.cccDtScCds.find(e => e.value === this.cccDtScCd).text, this.lstUnTuchPrdCdTxt]

          this.isSucs = true // 리턴값 세팅여부
          break
        // 수금인수고객
        case 'MSPCM322M':
          if (!this.fn_CheckMonthValue()) return // 유효성 검사

          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명
          this.lv_ReturnVal.undtkRsnScCdKey = this.inputUndtkRsnScCd // 인수구분
          this.lv_ReturnVal.startDate = this.fn_SetDate(this.startMonth) // 조회시작일자 해당월 첫째날 셋팅
          this.lv_ReturnVal.endDate = this.fn_SetDate(this.endMonth) // 조회종료일자 해당월 마지막날 셋팅

          // 초기화 여부 체크
          let setSdate = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.nowDate).add(-6, 'months')), 'String') // 해당월 1일
          let setEdate = this.$bizUtil.getLastDayOfMonth(new Date(moment(new Date()).format('YYYY-MM-DD')), 'String') // 해당월 말일
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword) && this.lv_ReturnVal.undtkRsnScCdKey === '0' && this.lv_ReturnVal.lstUnTuchPrdCd === ' '
              && this.lv_ReturnVal.startDate === [String(moment(setSdate).format('YYYY')), String(moment(setSdate).format('MM'))]
              && this.lv_ReturnVal.endDate === [String(moment(setEdate).format('YYYY')), String(moment(setEdate).format('MM'))]) {
            if (this.pIsMngr) {
              if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
              else this.isInit = false
            } else this.isInit = true
          }

          // 조회조건: 고객명, 조회시작일자, 조회종료일자(인수구분 사용하지 않음)
          this.lv_ReturnStr = [this.lv_ReturnVal.searchKeyword, this.lv_ReturnVal.startDate, this.lv_ReturnVal.endDate]

          this.isSucs = true // 리턴값 세팅여부
          break
        case 'MSPCM330M': // 추가계약 추천고객
          // 조회조건
          this.lv_ReturnStr = [this.searchKeyword, this.upselSxdsCds.find(e => e.value === this.upselSxdsCd).text,
                              this.upselAgeItvCds.find(e => e.value === this.upselAgeItvCd).text, this.upselGrdNms.find(e => e.value === this.upselGrdNm).text,
                              this.upselAsptnIncmItvCds.find(e => e.value === this.upselAsptnIncmItvCd).text]

          if (this.upselGrdNm === '전체') this.upselGrdNm = ''

          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명
          this.lv_ReturnVal.upselSxdsCd = this.upselSxdsCd  // 성별
          this.lv_ReturnVal.upselAgeItvCd = this.upselAgeItvCd  // 연령대
          this.lv_ReturnVal.upselGrdNm = this.upselGrdNm  // 추가계약가능성
          this.lv_ReturnVal.upselAsptnIncmItvCd = this.upselAsptnIncmItvCd  // 추정소득
          
          // 초기화 여부 체크
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword) && this.lv_ReturnVal.upselSxdsCd === '0' && this.lv_ReturnVal.upselAgeItvCd === '0'
              && this.lv_ReturnVal.upselGrdNm === '' && this.lv_ReturnVal.upselAsptnIncmItvCd === '00') {
            this.isInit = true
          }

          this.isSucs = true // 리턴값 세팅여부
          break
        case 'MSPCM135M': // 비대면동의 현황조회
          // 조회조건
          this.lv_ReturnStr = [this.startDate, this.endDate, this.searchKeyword, this.telNo]

          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 고객명
          this.lv_ReturnVal.telNo = this.telNo // 휴대폰번호
          this.lv_ReturnVal.startDate = this.startDate // 조회시작일자
          this.lv_ReturnVal.endDate = this.endDate // 조회종료일자

          // 휴대폰번호 조회할 경우 전체고객 대상으로 조회
          if (this.lv_ReturnVal.telNo) {
            this.lv_ReturnVal.cnsltEno = ''
            this.lv_ReturnVal.hofOrgCd = ''
            this.lv_ReturnVal.dofOrgCd = ''
            this.lv_ReturnVal.fofOrgCd = ''
            this.lv_ReturnVal.pOrgData = {}
          }
          
          // 초기화 여부 체크
          if (this.$bizUtil.isEmpty(this.lv_ReturnVal.searchKeyword) && this.$bizUtil.isEmpty(this.lv_ReturnVal.telNo)
            && this.$bizUtil.isEmpty(this.lv_ReturnVal.startDate) && this.$bizUtil.isEmpty(this.lv_ReturnVal.endDate)) {
            if (this.pIsMngr) {
              if (this.userInfo.userId === this.lv_ReturnVal.cnsltEno) this.isInit = true
              else this.isInit = false
            } else this.isInit = true
          }

          this.isSucs = true // 리턴값 세팅여부
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search () {
      this.fn_SetReturnVal() // 리턴값 세팅

      if (this.isSucs) {
        this.$emit('onPopupSearch', { rtnData: this.lv_ReturnVal, srchOpt: this.lv_ReturnStr, isInit: this.isInit });
      }
    },
    /******************************************************************************
     * Function명 : fn_ChangeStartDate
     * 설명       : 인수월 조회시작기간 변경 시 조회종료기간 범위 변경
     ******************************************************************************/
    fn_ChangeStartDate(date, isOverwirte = true) {
      if (!date || !date.length) return;

      date[1] = date[1].padStart(2, '0') // IOS에서 month 두 글자 아닐 시 에러 발생 (기본 브라우저 차이) ex) 4 -> Error, 04 -> OK

      const startDate = moment(new Date(date.join('-')).toISOString());
      this.searchEndMinDate = startDate.format('YYYY-MM-DD');
      const endDate = startDate.add(11, 'months');
      this.searchEndMaxDate = endDate.isAfter(this.curDate) ? this.curDate : endDate.format('YYYY-MM-DD');
      
      if (isOverwirte) {
        const [year, month] = this.searchEndMaxDate.split('-');
        this.endMonth = [year, month];
      }
    },
    /******************************************************************************
     * Function명 : fn_ResetSearchRangeDate
     * 설명       : 인수월 조회기간 범위 초기화
     ******************************************************************************/
    fn_ResetSearchRangeDate() {
      this.fn_ChangeStartDate(this.startMonth, false);
    },
  }
}
</script>
